import {
  Back,
  WR,
} from 'CORE__UI/apps/DataNotFoundApp/core__dataNotFoundReact';
import styled from 'styled-components';
import { BREAKPOINT_S } from '../../globals/breakpoints';
import { GREYS } from '../../globals/colours';
const Back_JN = styled(Back)`
  color: ${GREYS.white};
  margin-inline: auto;
  margin-block: 16px;
`;
const WR_JN = styled(WR)`
  width: max-content;
  margin-inline: auto;
  @media (max-width: ${BREAKPOINT_S}) {
    width: 80%;
    margin: auto;
  }
`;
export { Back_JN as Back, WR_JN as WR };
