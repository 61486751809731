import {
  ND,
  W,
  P,
  A,
  LC,
  ST,
} from 'CORE__UI/globals/NoEventWithBtn/core__noEventStylesWithBtn';
import styled, { css } from 'styled-components';
import { GREYS } from './colours';
const ND_JN = styled(ND)`
  word-wrap: break-word;
`;
const W_JN = styled(W)`
  padding: 64px;
  margin: 20px auto auto auto;
  text-align: center;
  word-break: break-all;
  ${({ showJNStyle }) =>
    showJNStyle &&
    css`
      text-align: left;
      margin: 20px auto auto auto;
      background-color: ${GREYS.silverD};
    `}
`;
const ST_JN = styled(ST)`
  font-weight: 700;
`;
const LC_JN = styled(LC)`
  padding: 0;
  margin: auto;
  background: inherit;
  width: inherit;
  height: inherit;
  justify-content: center;
  ${({ showJNStyle }) =>
    showJNStyle &&
    css`
      margin: 0;
      padding: 0;
      height: inherit;
      width: inherit;
      justify-content: left;
    `}
`;
const A_JN = styled(A)`
  padding: 0;
  margin: 0;
  text-decoration: underline;
  ${({ showJNStyle }) =>
    showJNStyle &&
    css`
      margin: 4px;
    `}
`;
const P_JN = styled(P)`
  color: ${GREYS.white};
  margin-block: 8px;
  margin-inline: 0;
  word-wrap: break-word;
`;
export {
  ND_JN as ND,
  W_JN as W,
  P_JN as P,
  A_JN as A,
  LC_JN as LC,
  ST_JN as ST,
};
